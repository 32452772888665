import { configureStore } from '@reduxjs/toolkit';
import userStatusReducer from '../slice/userStatusSlice';
import contextReducer from '../slice/contextSlice';
import markdownReducer from '../slice/markdownSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const userStatusPersistConfig = {
  key: 'userStatus',
  storage,
  blacklist: []
};

const contextPersistConfig = {
  key: 'context',
  storage,
  blacklist: []
};

const markdownPersistConfig = {
  key: 'markdown',
  storage,
  blacklist: []
};


export const store = configureStore({
  reducer: {
    userStatus: persistReducer(userStatusPersistConfig, userStatusReducer),
    context: persistReducer(contextPersistConfig, contextReducer),
    markdown: persistReducer(markdownPersistConfig, markdownReducer),
  },
});
export const persistor = persistStore(store);


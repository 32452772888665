import React from 'react';
import Loadable from 'react-loadable';
import Loading from "../../common/Loading";

const LoadableBar = Loadable({
  loader: () => import('../Md5Generator'),
  loading: Loading,
  delay: 300,
  timeout: 5000, // ms
});

class LoadableMd5Generator extends React.Component {
  render() {
    return <LoadableBar/>;
  }
}

export default LoadableMd5Generator;

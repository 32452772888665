import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {SmileOutlined, ArrowRightOutlined, StarOutlined, StarFilled} from '@ant-design/icons';
import {Avatar, Card, Row, Col, message, Popover, Result, Button, Tabs} from 'antd';
import axios from "axios";
import {TOOLS_META_INFO, ICON, TITLE, DESCRIPTION, ROUTE, SEARCH_KEYWORD, CATEGORY} from "../constants/ToolsMetaInfo";
import {updateToolCategoryTabKey} from '../slice/contextSlice';
const {Meta} = Card;

function TotalTools(props) {
  const loginStatus = useSelector((state) => state.userStatus.loginStatus);
  const accessToken = useSelector((state) => state.userStatus.accessToken);
  const currentRoute = useSelector((state) => state.context.currentRoute);
  const toolCategoryTabKey = useSelector((state) => state.context.toolCategoryTabKey);
  const [collectedToolNames, setCollectedToolNames] = useState([]);
  const [displayToolNames, setDisplayToolNames] = useState([]);
  const dispatch = useDispatch();

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  useEffect(() => {
    // console.log('running useEffect...')
    fetchCollectedTools();
    const toolsInfo = Object.values(TOOLS_META_INFO);
    if (props.type === 'total' && !arraysEqual(toolsInfo, displayToolNames)) {
      setDisplayToolNames(toolsInfo);
      return;
    }

    if (props.type === 'collected') {
      let res = [];
      for (let i = 0; i < collectedToolNames.length; ++i) {
        // console.log(collectedToolNames[i])
        if (collectedToolNames[i] in TOOLS_META_INFO) {
          res.push(TOOLS_META_INFO[collectedToolNames[i]]);
        }
      }
      if (!arraysEqual(res, displayToolNames)) {
        setDisplayToolNames(res);
      }
      return;
    }
    if (props.type === 'search') {
      const search = props.location.search;
      const q = search.substring(3, search.length).toLowerCase();
      let searchRes = [];
      for (let i = 0; i < toolsInfo.length; ++i) {
        const toolInfo = toolsInfo[i];
        if (toolInfo[TITLE].toLowerCase().search(q) !== -1 || toolInfo[SEARCH_KEYWORD].toLowerCase().search(q) !== -1) {
          searchRes.push(toolInfo);
        }
      }
      if (!arraysEqual(searchRes, displayToolNames)) {
        setDisplayToolNames(searchRes);
      }
    }
  }, [props.location.pathname, currentRoute, collectedToolNames, displayToolNames]);

  function fetchCollectedTools() {

    if (loginStatus === 'loggedIn') {
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': accessToken},
        data: {},
        url: '/api/tools/collected_tools'
      };
      axios(options).then(res => {
        if (!arraysEqual(res.data.collectedToolNames, collectedToolNames)) {
          // console.log('request...')
          // console.log(res.data.collectedToolNames)
          setCollectedToolNames(res.data.collectedToolNames);
        }
      }).catch(function (e) {
        // console.log(e);
        message.error('异常');
      });
    } else {
      if (collectedToolNames.length > 0) {
        setCollectedToolNames([]);
      }
    }
  }

  function handleCollect(toolName, op) {
    if (loginStatus !== 'loggedIn') {
      message.error('请先登录');
      return;
    }
    const request = {'toolName': toolName, 'op': op};
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': accessToken},
      data: request,
      url: '/api/tools/collect'
    };
    axios(options).then(res => {
      if (res.data.success && op === 'collect') {
        message.success('收藏成功');
        fetchCollectedTools();
      } else if (!res.data.success && op === 'collect') {
        message.error('收藏失败');
      } else if (res.data.success && op === 'cancel_collect') {
        fetchCollectedTools();
        message.success('取消成功');
      } else if (!res.data.success && op === 'cancel_collect') {
        message.error('取消失败');
      }
    }).catch(function (e) {
      // console.log(e);
      message.error('网络异常');
    });
  }

  function handleEntry(toolName) {
    props.history.push('/' + toolName);
  }

  function navToTotals() {
    dispatch(updateToolCategoryTabKey({toolCategoryTabKey: 'tools'}));
    props.history.push('/tools');
  }

  function tabChange(key) {
    dispatch(updateToolCategoryTabKey({toolCategoryTabKey: key}))
  }

  const items = [
    {
      key: 'tools',
      label: '所有',
      children: renderTools(Object.values(TOOLS_META_INFO)),
    },
    {
      key: 'dev_tools',
      label: '开发',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '开发')),
    },
    {
      key: 'image_tools',
      label: '图片',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '图片')),
    },
    {
      key: 'txt_tools',
      label: '文本',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '文本')),
    },
    {
      key: 'time_tools',
      label: '时间',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '时间')),
    },
    {
      key: 'finance_tools',
      label: '财务',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '财务')),
    },
    {
      key: 'other_tools',
      label: '其他',
      children: renderTools(Object.values(TOOLS_META_INFO).filter(x => x[CATEGORY] === '其他')),
    },
  ];

  function renderTools(toolNames) {
    return <Row gutter={[16, 16]}>
      {toolNames.map((toolInfo) => {
        return (
          <Col span={8}>
            <Card
              actions={
                collectedToolNames.includes(toolInfo[ROUTE]) ?
                  ([
                    <Popover content="已收藏，点击取消收藏">
                      <StarFilled key="collect" onClick={() => handleCollect(toolInfo[ROUTE], 'cancel_collect')}/>
                    </Popover>,
                    <Popover content="开始使用">
                      <ArrowRightOutlined key="entry" onClick={() => handleEntry(toolInfo[ROUTE])}/>
                    </Popover>
                  ]) :
                  ([
                    <Popover content="点击加入收藏">
                      <StarOutlined key="collect" onClick={() => handleCollect(toolInfo[ROUTE], 'collect')}/>
                    </Popover>,
                    <Popover content="开始使用">
                      <ArrowRightOutlined key="entry" onClick={() => handleEntry(toolInfo[ROUTE])}/>
                    </Popover>
                  ])
              }
            >
              <Meta
                avatar={<Avatar src={toolInfo[ICON]}/>}
                title={<a href={toolInfo[ROUTE]}>{toolInfo[TITLE]}</a>}
                description={toolInfo[DESCRIPTION]}
              />
            </Card>
          </Col>
        )
      })}
    </Row>
  }

  function render(toolNames) {
    if (props.type === 'total') {
      return <Tabs items={items} activeKey={toolCategoryTabKey} onChange={tabChange} />;
    }
    if (props.type === 'collected' || props.type === 'search') {
      return renderTools(displayToolNames);
    }
  }


  return (
    <Card style={{marginTop: '10px', minHeight: 'calc(100vh - 80px)'}}>
      {loginStatus === 'loggedOut' && props.type === 'collected' ?
        (
          <Result
            style={{marginTop: '50px'}}
            icon={<SmileOutlined/>}
            title="抱歉，请先登录！"
            extra={<Button type="primary" onClick={navToTotals}>查看所有工具</Button>}
          />
        ) : (null)}
      {loginStatus === 'loggedIn' && displayToolNames.length === 0 && props.type === 'collected' ?
        (
          <Result
            style={{marginTop: '50px'}}
            icon={<SmileOutlined/>}
            title="抱歉，当前并没有收藏工具！"
            extra={<Button type="primary" onClick={navToTotals}>查看所有工具</Button>}
          />
        ) : (null)}
      {displayToolNames.length === 0 && props.type === 'search' ?
        (
          <Result
            style={{marginTop: '50px'}}
            icon={<SmileOutlined/>}
            title="抱歉，没有找到相关工具！"
            extra={<Button type="primary" onClick={navToTotals}>查看所有工具</Button>}
          />
        ) : (null)}

      {render()}



    </Card>
  );

}


export default withRouter(TotalTools);

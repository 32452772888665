// key是工具名称，key和route必须保持一致
// 必备项：route、icon、title、description、category、category_route、search_keyword
export const TOOLS_META_INFO = {
  "base64": {
    "route": "base64",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/base64.png",
    "title": "Base64",
    "description": "文本/图片Base64编码解码",
    "category": "开发",
    "category_route": "dev_tools",
    "search_keyword": "文本Base64编码解码,图片Base64编码解码"
  },
  "json_format": {
    "route": "json_format",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/json_format.png",
    "title": "JSON格式化",
    "description": "JSON格式化校验工具",
    "category": "开发",
    "category_route": "dev_tools",
    "search_keyword": "JSON格式化校验工具"
  },
  "json_editor": {
    "route": "json_editor",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/json_editor.png",
    "title": "JSON编辑器",
    "description": "JSON编辑器",
    "category": "开发",
    "category_route": "dev_tools",
    "search_keyword": "JSON编辑器"
  },
  "md5": {
    "route": "md5",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/md5.png",
    "title": "MD5摘要算法",
    "description": "MD5摘要算法",
    "category": "开发",
    "category_route": "dev_tools",
    "search_keyword": "MD5摘要算法"
  },


  "mini_paint": {
    "route": "mini_paint",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/min_paint.png",
    "title": "miniPaint",
    "description": "在线绘图工具",
    "category": "图片",
    "category_route": "image_tools",
    "search_keyword": "在线绘图工具,画图工具"
  },
  "photopea": {
    "route": "photopea",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/photopea.png",
    "title": "在线PS",
    "description": "在线PS",
    "category": "图片",
    "category_route": "image_tools",
    "search_keyword": "在线PS,photopea,在线photoshop"
  },
  "image_editor": {
    "route": "image_editor",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/image-edit.png",
    "title": "在线图片编辑工具",
    "description": "在线图片编辑工具",
    "category": "图片",
    "category_route": "image_tools",
    "search_keyword": "在线图片编辑工具,图片处理"
  },
  "image_compressor": {
    "route": "image_compressor",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/image_compressor.png",
    "title": "图片压缩",
    "description": "图片压缩",
    "category": "图片",
    "category_route": "image_tools",
    "search_keyword": "在线图片压缩"
  },
  "color_value_converter": {
    "route": "color_value_converter",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/drawing_palette.png",
    "title": "颜色值转换工具",
    "description": "颜色值转换工具",
    "category": "图片",
    "category_route": "image_tools",
    "search_keyword": "颜色值转换工具,rgb转换十六进制"
  },


  "text_diff": {
    "route": "text_diff",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/diff.png",
    "title": "文本对比",
    "description": "文本差异对比，文本比较工具",
    "category": "文本",
    "category_route": "txt_tools",
    "search_keyword": "文本比较工具,文本diff"
  },


  "timestamp": {
    "route": "timestamp",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/timestamp.png",
    "title": "Unix时间戳",
    "description": "Unix时间戳工具",
    "category": "时间",
    "category_route": "time_tools",
    "search_keyword": "Unix时间戳工具,timestamp"
  },

  "renminbi_uppercase": {
    "route": "renminbi_uppercase",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/renminbi_upper.png",
    "title": "人民币金额大写转换器",
    "description": "人民币金额大写转换器",
    "category": "财务",
    "category_route": "finance_tools",
    "search_keyword": "人民币金额大写转换器"
  },

  "down_git": {
    "route": "down_git",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/github-fill.png",
    "title": "Github文件/文件夹下载",
    "description": "Github文件/文件夹下载",
    "category": "其他",
    "category_route": "other_tools",
    "search_keyword": "Github文件/文件夹下载"
  },

  "morse_code": {
    "route": "morse_code",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/morse_code.png",
    "title": "摩斯电码",
    "description": "摩斯电码",
    "category": "其他",
    "category_route": "other_tools",
    "search_keyword": "摩斯电码"
  },

  "markdown": {
    "route": "markdown",
    "icon": "https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/icon/markdown-line.png",
    "title": "Markdown编辑器",
    "description": "在线Markdown编辑器",
    "category": "文本",
    "category_route": "txt_tools",
    "search_keyword": "在线markdown编辑器"
  },

}

export const CATEGORY_ROUTE = "category_route";
export const ROUTE = "route";
export const ICON = "icon";
export const TITLE = "title";
export const DESCRIPTION = "description"
export const CATEGORY = "category";
export const SEARCH_KEYWORD = "search_keyword";

import React  from 'react';

function Loading(props) {
  if (props.error) {
    return <div>抱歉，出错了！请尝试刷新页面!</div>;
  } else if (props.timedOut) {
    return <div>加载中，请耐心等待...</div>;
  } else if (props.pastDelay) {
    return <div>加载中...</div>;
  } else {
    return null;
  }
}

export default Loading;

import { createSlice } from '@reduxjs/toolkit'

export const markdownSlice = createSlice({
  name: 'markdown',
  initialState: {
    title: '',
    content: ''
  },
  reducers: {
    updateTitle: (state, action) => {
      state.title = action.payload.title;
    },

    updateContent: (state, action) => {
      state.content = action.payload.content;
    },
  },
});

export const { updateTitle, updateContent } = markdownSlice.actions;

export default markdownSlice.reducer

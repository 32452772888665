import React, {useEffect} from 'react';
import {Layout, message, Modal, Input, List, Space, Avatar} from 'antd';
import {withRouter} from 'react-router-dom'
import hotkeys from 'hotkeys-js';
import {useState, useRef} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {ICON, ROUTE, SEARCH_KEYWORD, TITLE, TOOLS_META_INFO} from "../constants/ToolsMetaInfo";
import {useSelector} from "react-redux";

function Footer(props) {
  const footerDisplay = useSelector((state) => state.context.footerDisplay);

  const [searchModelVisible, setSearchModelVisible] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedToolIndex, setSelectedToolIndex] = useState(-1);
  const [query, setQuery] = useState('');

  const inputRef = useRef(null);

  const sharedProps = {
    ref: inputRef,
  };

  // 上下键
  const keyHandler = (e) => {
    if(e.code === 'ArrowDown'){
      console.log('down')
      if (selectedToolIndex < searchResult.length - 1) {
        setSelectedToolIndex(selectedToolIndex + 1);
      }
      // console.log('selectedToolIndex: ' + selectedToolIndex + ' searchResult.length: ' + searchResult.length)
    }
    if(e.code === 'ArrowUp'){
      console.log('up')
      if (selectedToolIndex > 0) {
        setSelectedToolIndex(selectedToolIndex - 1);
      }
      // console.log('selectedToolIndex: ' + selectedToolIndex + ' searchResult.length: ' + searchResult.length)
    }
  };

  useEffect(() => {
    // document.unbind('keyup');
    window.addEventListener('keydown', keyHandler, false);
    // 在unmount的时候需要remove listener，不然后重复执行好多次，直至页面卡死
    // keep the handler outside useEffect
    // we would just be attaching that function on mount and removing on unmount.
    return () => window.removeEventListener('keydown', keyHandler, false);
  }, [selectedToolIndex]);


  hotkeys('ctrl+t,d+f', function(event, handler){
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault()
    setSearchModelVisible(true);
    inputRef.current.focus({
      cursor: 'start',
    });
    setQuery('');
    setSearchResult([]);
    setSelectedToolIndex(-1);
  });

  function onChange(e) {
    const curQuery = e.target.value;
    if (curQuery === '') {
      setQuery(e.target.value.toLowerCase())
      setSearchResult([])
      return;
    }
    const toolsInfo = Object.values(TOOLS_META_INFO);
    let searchRes = [];
    for (let i = 0; i < toolsInfo.length; ++i) {
      const toolInfo = toolsInfo[i];
      if (toolInfo[ROUTE].toLowerCase().search(curQuery) !== -1
        || toolInfo[TITLE].toLowerCase().search(curQuery) !== -1
        || toolInfo[SEARCH_KEYWORD].toLowerCase().search(curQuery) !== -1) {
        searchRes.push(toolInfo);
      }
    }

    setSearchResult(searchRes);
    setQuery(e.target.value.toLowerCase());

    if (searchRes.length > 0) {
      setSelectedToolIndex(0);
    }
    // console.log('curQuery: ' + curQuery + ' result size: ' + searchRes.length + ' selectedToolIndex: ' + selectedToolIndex)
  }

  function onPressEnter() {
    if (searchResult.length === 0) {
      message.error('抱歉，未搜索到相关工具！')
    }
    setSearchModelVisible(false);
    props.history.push('/' + searchResult[selectedToolIndex][ROUTE]);
  }

  return (
    <Layout
      style={{
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '10pt',
        display: footerDisplay,
      }}
    >
      <row><div><span>Copyright © 2022-2023 mytoolbox.work All Rights Reserved</span></div></row>
      <row style={{marginTop:'5px'}}><div><a href='https://beian.miit.gov.cn/'>沪ICP备2021029167号-2</a></div></row>

      {/*全局搜索*/}
      <Modal footer={null}
             width='70%'
             open={searchModelVisible}
             zIndex={10000}
             onCancel={() => setSearchModelVisible(false)} >
        <Input
          {...sharedProps}
          onChange={onChange}
          onPressEnter={onPressEnter}
          prefix={<SearchOutlined />}
          placeholder="搜索工具"
          allowClear
          size='large'
          value={query}
          style={{marginTop: '30px'}}
        />
        <List
          bordered
          dataSource={searchResult}
          renderItem={
            (item) => {
              return (
                selectedToolIndex >= 0 && searchResult[selectedToolIndex][ROUTE] === item[ROUTE] ?
                  (<List.Item size='large' style={{backgroundColor: '#F5F5F5'}}>
                    <div style={{width: '100%'}}>
                      <a href={item[ROUTE]} style={{color: '#3C5DF2'}}>
                        <Space>
                          <span>{item[TITLE]}</span>
                          <Avatar src={item[ICON]} />
                          <span>{'https://mytoolbox.work/' + item[ROUTE]}</span>
                        </Space>
                      </a>

                    </div>
                  </List.Item>):
                  (<List.Item size='large'>
                    <div style={{width: '100%'}}>
                      <a href={item[ROUTE]} style={{color: 'black'}}>
                        <Space>
                          <span>{item[TITLE]}</span>
                          <Avatar src={item[ICON]} />
                          <span>{'https://mytoolbox.work/' + item[ROUTE]}</span>
                        </Space>
                      </a>
                    </div>
                  </List.Item>)
              );
            }}
        />


      </Modal>
    </Layout>



  );

}


export default withRouter(Footer);

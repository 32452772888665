import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Layout, Result, Button, Card} from 'antd';
import { FrownOutlined } from '@ant-design/icons';


class NotFound404 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }


  render() {

    return (
      <Card style={{marginTop: "10px"}}>

        {/*<Result*/}
        {/*  status="404"*/}
        {/*  title="404"*/}
        {/*  style={{*/}
        {/*    background: 'none',*/}
        {/*  }}*/}
        {/*  subTitle="抱歉，您访问的页面不存在！"*/}
        {/*  extra={*/}
        {/*    <Link to="/">*/}
        {/*      <Button type="primary">返回首页</Button>*/}
        {/*    </Link>*/}
        {/*  }*/}
        {/*/>*/}
        <Result
          style={{marginTop: '50px', minHeight: 'calc(100vh - 180px)'}}
          icon={<FrownOutlined />}
          title="抱歉，您访问的页面不存在！"
          extra={
            <Link to="/">
              <Button type="primary">返回首页</Button>
            </Link>
          }
        />
      </Card>
    );
  }
}

export default NotFound404;

import {Button, Card, List, message, Skeleton} from 'antd';
import {useEffect, useState} from 'react';
import axios from "axios";

const Hot = () => {
  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [baiduData, setBaiduData] = useState([]);
  const [listData, setListData] = useState([]);
  const [buttonDisplay, setButtonDisplay] = useState('inline-block');

  const [listData2, setListData2] = useState([]);
  const [buttonDisplay2, setButtonDisplay2] = useState('inline-block');

  useEffect(() => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      data: {},
      url: '/api/hot/fetching_baidu'
    };
    axios(options).then(res => {
      setBaiduData(res.data.news);
      // setListData(res.data.news);
      setListData(res.data.news.slice(0, 5));
      setListData2(res.data.news.slice(0, 5));
    }).catch(function (e) {
      console.log(e);
      message.error('网络异常')
    });
  }, []);

  const onLoadMore = () => {
    setLoading(false);
    setListData(baiduData);
    setButtonDisplay('none');
  };
  const onLoadMore2 = () => {
    setLoading(false);
    setListData2(baiduData);
    setButtonDisplay2('none');
  };
  const loadMore = (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore} style={{display: buttonDisplay}}>全部展开</Button>
      </div>
    );
  const loadMore2 = (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button onClick={onLoadMore2} style={{display: buttonDisplay2}}>全部展开</Button>
    </div>
  );
  return (
    <Card style={{marginTop: '10px'}}>
      <List
        size="large"
        header={<div>百度热搜</div>}
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={listData}
        renderItem={(item) => (
          <List.Item
            actions={[<a key="list-loadmore-edit" href={item.detail_url} target='_blank'>查看详情</a>,]}
          >
            <Skeleton avatar title={false} loading={false} active>
              <List.Item.Meta
                title={<a href={item.detail_url} target='_blank'>{item.id}.{item.title}</a>}
              />
              <div>{item.index}</div>
            </Skeleton>
          </List.Item>
        )}
      />

      <List
        size="large"
        header={<div>百度热搜</div>}
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore2}
        dataSource={listData2}
        renderItem={(item) => (
          <List.Item
            actions={[<a key="list-loadmore-edit" href={item.detail_url} target='_blank'>查看详情</a>,]}
          >
            <Skeleton avatar title={false} loading={false} active>
              <List.Item.Meta
                title={<a href={item.detail_url} target='_blank'>{item.id}.{item.title}</a>}
              />
              <div>{item.index}</div>
            </Skeleton>
          </List.Item>
        )}
      />
    </Card>
  );
};
export default Hot;
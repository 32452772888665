import React from 'react';
import {Switch, Route} from 'react-router-dom';
import 'antd/dist/reset.css';

import {withRouter} from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';

import NotFound404 from "../common/NotFound404";
import {Layout, Breadcrumb} from 'antd';
import TotalTools from "../tools/TotalTools";
import {useSelector} from "react-redux";
import LoadableJsonEditor from "../tools/loadable/LoadableJsonEditor";
import LoadableTextDiff from "../tools/loadable/LoadableTextDiff";
import LoadableBase64 from "../tools/loadable/LoadableBase64";
import LoadableJsonFormat from "../tools/loadable/LoadableJsonFormat";
import LoadableTimestamp from "../tools/loadable/LoadableTimestamp";
import LoadableMorseCode from "../tools/loadable/LoadableMorseCode";
import LoadableRenMinBi from "../tools/loadable/LoadableRenMinBi";
import LoadableMd5Generator from "../tools/loadable/LoadableMd5Generator";
import LoadableImageCompressor from "../tools/loadable/LoadableImageCompressor";
import LoadableToastUiImageEditor from "../tools/loadable/LoadableToastUiImageEditor";
import LoadableMiniPaint from "../tools/loadable/LoadableMiniPaint";
import LoadablePhotopea from "../tools/loadable/LoadablePhotopea";
import LoadableColorValueTransform from "../tools/loadable/LoadableColorValueTransform";
import LoadableDownGit from "../tools/loadable/LoadableDownGit";
import LoadableAccountSetting from "../user/loadable/LoadableAccountSetting";
import LoadableSignup from "../user/loadable/LoadableSignup";
import LoadableLogin from "../user/loadable/LoadableLogin";
import Hot from "../tools/Hot";
import LoadableFeedback from "../basic/LoadableFeedback";
import LoadableMarkdown from "../tools/loadable/LoadableMarkdown";

const {Content} = Layout;

const App = () => {
  const breadcrumb1 = useSelector((state) => state.context.breadcrumb1);
  const breadcrumb2 = useSelector((state) => state.context.breadcrumb2);
  const breadcrumb3 = useSelector((state) => state.context.breadcrumb3);
  const breadcrumb1_href = useSelector((state) => state.context.breadcrumb1_href);
  const breadcrumb2_href = useSelector((state) => state.context.breadcrumb2_href);
  const breadcrumb3_href = useSelector((state) => state.context.breadcrumb3_href);

  function breadcrumb() {
    if (typeof(breadcrumb1) !== "undefined" && breadcrumb1 !== null && breadcrumb1 !== ''
      && typeof(breadcrumb2) !== "undefined" && breadcrumb2 !== null && breadcrumb2 !== ''
      && typeof(breadcrumb3) !== "undefined" && breadcrumb3 !== null && breadcrumb3 !== '') {
      return <Breadcrumb style={{margin: '16px 0'}}>
        <Breadcrumb.Item><a href={breadcrumb1_href}>{breadcrumb1}</a></Breadcrumb.Item>
        <Breadcrumb.Item><a href={breadcrumb2_href}>{breadcrumb2}</a></Breadcrumb.Item>
        <Breadcrumb.Item><a href={breadcrumb3_href}>{breadcrumb3}</a></Breadcrumb.Item>
      </Breadcrumb>;
    }
    if (typeof(breadcrumb1) !== "undefined" && breadcrumb1 !== null && breadcrumb1 !== ''
      && typeof(breadcrumb2) !== "undefined" && breadcrumb2 !== null && breadcrumb2 !== '') {
      return <Breadcrumb style={{margin: '16px 0'}}>
        <Breadcrumb.Item><a href={breadcrumb1_href}>{breadcrumb1}</a></Breadcrumb.Item>
        <Breadcrumb.Item><a href={breadcrumb2_href}>{breadcrumb2}</a></Breadcrumb.Item>
      </Breadcrumb>;
    }
    if (typeof(breadcrumb1) !== "undefined" && breadcrumb1 !== null && breadcrumb1 !== '') {
      return <Breadcrumb style={{margin: '16px 0'}}>
        <Breadcrumb.Item><a href={breadcrumb1_href}>{breadcrumb1}</a></Breadcrumb.Item>
      </Breadcrumb>;
    }
    return <Breadcrumb/>;
  }

  return (
    <Layout style={{
      minWidth: '800px',
    }}>
      <Header/>
      <Content
        className="site-layout"
        style={{
          padding: '0 50px',
          minHeight: 'calc(100vh - 100px)',
        }}
      >
        {breadcrumb()}

        <div>
          <Switch>
            <Route path="/" exact render={(props) => <TotalTools type='total' {...props}  />}/>
            <Route path="/tools" exact render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/my_tools" exact render={(props) => <TotalTools type='collected' {...props} />}/>
            <Route path="/search" render={(props) => <TotalTools type='search' {...props} />}/>
            <Route path="/dev_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/image_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/txt_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/txt_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/time_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/finance_tools" render={(props) => <TotalTools type='total' {...props} />}/>
            <Route path="/other_tools" render={(props) => <TotalTools type='total' {...props} />}/>

            <Route path="/text_diff" exact render={(props) => <LoadableTextDiff {...props} />}/>
            <Route path="/timestamp" exact render={(props) => <LoadableTimestamp {...props} />}/>
            <Route path="/json_format" exact render={(props) => <LoadableJsonFormat {...props} />}/>
            <Route path="/json_editor" exact render={(props) => <LoadableJsonEditor {...props} />}/>
            <Route path="/down_git" exact render={(props) => <LoadableDownGit {...props} />}/>
            <Route path="/md5" exact render={(props) => <LoadableMd5Generator {...props} />}/>
            <Route path="/morse_code" exact render={(props) => <LoadableMorseCode {...props} />}/>
            <Route path="/renminbi_uppercase" exact render={(props) => <LoadableRenMinBi {...props} />}/>
            <Route path="/base64" exact render={(props) => <LoadableBase64 {...props} />}/>
            <Route path="/mini_paint" exact render={(props) => <LoadableMiniPaint {...props} />}/>
            <Route path="/photopea" exact render={(props) => <LoadablePhotopea {...props} />}/>
            <Route path="/image_editor" exact render={(props) => <LoadableToastUiImageEditor {...props} />}/>
            <Route path="/image_compressor" exact render={(props) => <LoadableImageCompressor {...props} />}/>
            <Route path="/color_value_converter" exact render={(props) => <LoadableColorValueTransform {...props} />}/>
            <Route path="/markdown" exact render={(props) => <LoadableMarkdown {...props} />}/>


            <Route path="/hot" exact render={(props) => <Hot {...props} />}/>


            <Route path="/login" exact render={(props) => <LoadableLogin {...props} />}/>
            <Route path="/signup" exact render={(props) => <LoadableSignup {...props} />}/>
            {/*<Route path="/account/center" exact render={(props) => <LoadableAccountCenter {...props} />}/>*/}
            <Route path="/account/setting" exact render={(props) => <LoadableAccountSetting {...props} />}/>
            <Route path="/feedback" exact render={(props) => <LoadableFeedback {...props} />}/>


            <Route render={(props) => <NotFound404 {...props} />}/>
          </Switch>
        </div>

      </Content>
      <Footer/>
    </Layout>
  )
};

export default withRouter(App);

import { createSlice } from '@reduxjs/toolkit'

export const contextSlice = createSlice({
  name: 'context',
  initialState: {
    // 控制header的display样式
    headerDisplay: 'block',
    // 控制footer的display样式
    footerDisplay: 'block',
    // loading
    globalLoading: 'false',

    menuKey: '',
    breadcrumb1: '',
    breadcrumb2: '',
    breadcrumb3: '',
    breadcrumb1_href: '',
    breadcrumb2_href: '',
    breadcrumb3_href: '',
    currentRoute: '',

    // 工具tab: 所有 开发 图片...
    toolCategoryTabKey: 'tools',

    // 更改密码对话框
    passwordModalVisible: false,
    // 更改邮箱对话框
    emailModalVisible: false,

  },
  reducers: {
    updateMenuKey: (state, action) => {
      state.menuKey = action.payload.menuKey;
    },

    updateBreadcrumb: (state, action) => {
      state.breadcrumb1 = action.payload.breadcrumb1;
      state.breadcrumb2 = action.payload.breadcrumb2;
      state.breadcrumb3 = action.payload.breadcrumb3;
      state.breadcrumb1_href = action.payload.breadcrumb1_href;
      state.breadcrumb2_href = action.payload.breadcrumb2_href;
      state.breadcrumb3_href = action.payload.breadcrumb3_href;
    },

    updateCurrentRoute: (state, action) => {
      state.currentRoute = action.payload.currentRoute;
    },

    updateToolCategoryTabKey: (state, action) => {
      state.toolCategoryTabKey = action.payload.toolCategoryTabKey;
    },

    updatePasswordModalVisible: (state, action) => {
      state.passwordModalVisible = action.payload.passwordModalVisible;
    },

    updateEmailModalVisible: (state, action) => {
      state.emailModalVisible = action.payload.emailModalVisible;
    },

    updateHeaderDisplay: (state, action) => {
      state.headerDisplay = action.payload.headerDisplay;
    },

    updateFooterDisplay: (state, action) => {
      state.footerDisplay = action.payload.footerDisplay;
    },

    updateGlobalLoading: (state, action) => {
      state.globalLoading = action.payload.globalLoading;
    },

  },
});

export const { updateMenuKey, updateBreadcrumb, updateCurrentRoute, updateToolCategoryTabKey, updatePasswordModalVisible,
  updateEmailModalVisible, updateHeaderDisplay, updateFooterDisplay, updateGlobalLoading } = contextSlice.actions;

export default contextSlice.reducer

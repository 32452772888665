import React from 'react';
import Loadable from 'react-loadable';
import Loading from "../common/Loading";

const LoadableBar = Loadable({
  loader: () => import('./Feedback'),
  loading: Loading,
  delay: 300,
  timeout: 5000, // ms
});

class LoadableFeedback extends React.Component {
  render() {
    return <LoadableBar/>;
  }
}

export default LoadableFeedback;

import React, { useEffect } from 'react';
import {Dropdown, Avatar, Menu, Space} from 'antd';
import {SettingOutlined, LogoutOutlined} from '@ant-design/icons';
import {withRouter} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { updateUserStatus } from '../slice/userStatusSlice';
import {
  updateMenuKey,
  updateBreadcrumb,
  updateCurrentRoute,
  updateToolCategoryTabKey,
  updateHeaderDisplay, updateFooterDisplay
} from "../slice/contextSlice";
import Search from 'antd/es/input/Search';
import {TOOLS_META_INFO, CATEGORY, TITLE, ROUTE, CATEGORY_ROUTE} from '../constants/ToolsMetaInfo';

const items = [
  {key: 'tools', label: '工具'},
  {key: 'my_tools', label: '收藏'},
  {key: 'feedback', label: '反馈'},
];



function Header(props) {
  const currentUser = useSelector((state) => state.userStatus.currentUser);
  const loginStatus = useSelector((state) => state.userStatus.loginStatus);
  const headerDisplay = useSelector((state) => state.context.headerDisplay);
  const footerDisplay = useSelector((state) => state.context.footerDisplay);

  const menuKey = useSelector((state) => state.context.menuKey);
  const breadcrumb1 = useSelector((state) => state.context.breadcrumb1);
  const breadcrumb2 = useSelector((state) => state.context.breadcrumb2);
  const breadcrumb3 = useSelector((state) => state.context.breadcrumb3);
  const currentRoute = useSelector((state) => state.context.currentRoute);
  const dispatch = useDispatch();

  // useEffect的第一个参数是要调用的函数
  // 使用userEffect的第二个参数，第二个参数是第一个参数的依赖项，只有该变量发生变化时，副效应函数才会执行
  useEffect(() => {
    console.log('loading header...');
    // 百度统计-start
    (function() {
      let hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?de54fcb2f658f665722f686f5ccfb2c9";
      let s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
    // 百度统计-end

    // 确定header和footer的display样式
    const pathname = props.location.pathname;
    if (pathname === '/markdown') {
      dispatch(updateHeaderDisplay({headerDisplay: 'none'}));
      dispatch(updateFooterDisplay({footerDisplay: 'none'}));
    } else {
      dispatch(updateHeaderDisplay({headerDisplay: 'block'}));
      dispatch(updateFooterDisplay({footerDisplay: 'block'}));
    }

    updateMenuKeyAndBreadcrumb();
    dispatch(updateCurrentRoute({currentRoute: props.location.pathname}));
  }, [props.location.pathname, currentRoute, breadcrumb1, breadcrumb2, breadcrumb3, headerDisplay, footerDisplay]);

  function updateMenuKeyAndBreadcrumb() {
    const pathname = props.location.pathname;
    if (pathname === '/my_tools') {
      dispatch(updateMenuKey({menuKey: 'my_tools'}));
      dispatch(updateBreadcrumb({
        breadcrumb1: '我的收藏',
        breadcrumb1_href: 'my_tools',
      }))
      return;
    }
    if (pathname === '/tools' || pathname === '/') {
      dispatch(updateMenuKey({menuKey: 'tools'}));
      dispatch(updateBreadcrumb({
        // breadcrumb1: '所有工具',
        // breadcrumb1_href: 'tools',
      }))
      return;
    }
    if (pathname === '/markdown') {
      dispatch(updateMenuKey({menuKey: 'not_selected'}));
      dispatch(updateBreadcrumb({}))
      return;
    }


    if (pathname === '/feedback') {
      dispatch(updateMenuKey({menuKey: 'feedback'}));
      dispatch(updateBreadcrumb({
        breadcrumb1: '反馈',
        breadcrumb1_href: 'feedback',
      }))
      return;
    }
    const categoryRoute = ['/dev_tools', '/image_tools', '/txt_tools', '/time_tools', 'finance_tools', 'other_tools'];
    if (categoryRoute.indexOf(pathname) >= 0) {
      dispatch(updateToolCategoryTabKey({toolCategoryTabKey: pathname.substring(1, pathname.length)}));
    }
    if (pathname.substring(0, 7) === '/search') {
      dispatch(updateMenuKey({menuKey: 'not_selected'}));
      const search = props.location.search;
      const q = search.substring(3, search.length).toLowerCase();
      dispatch(updateBreadcrumb({
        breadcrumb1: '搜索 "'+ q + '"' + ' 相关的工具',
        breadcrumb1_href: ''
      }));
      return;
    }

    const route = pathname.substring(1, pathname.length);
    if (route in TOOLS_META_INFO) {
      dispatch(updateMenuKey({menuKey: 'not_selected'}));
      console.log(TOOLS_META_INFO[route][CATEGORY])
      console.log(TOOLS_META_INFO[route][TITLE])
      dispatch(updateBreadcrumb({
        breadcrumb1: '工具',
        breadcrumb2: TOOLS_META_INFO[route][CATEGORY],
        breadcrumb3: TOOLS_META_INFO[route][TITLE],
        breadcrumb1_href: 'tools',
        breadcrumb2_href: TOOLS_META_INFO[route][CATEGORY_ROUTE],
        breadcrumb3_href: TOOLS_META_INFO[route][ROUTE],
      }));
      return;
    }

    // default
    dispatch(updateMenuKey({menuKey: 'not_selected'}));
    dispatch(updateBreadcrumb({breadcrumb1: '', breadcrumb2: '', breadcrumb3: ''}));
  }


  function handleMenuClick(e) {
    dispatch(updateMenuKey({menuKey: e.key}));
    if (e.key === 'tools') {
      props.history.push('/tools');
    }
    if (e.key === 'my_tools') {
      props.history.push('/my_tools');
    }
    if (e.key === 'feedback') {
      props.history.push('/feedback');
    }
  }


  function handleLogout() {
    const action = {
      currentUser: null,
      accessToken: '',
      loginStatus: 'loggedOut'
    };
    dispatch(updateUserStatus(action));
    props.history.push('/');
  }

  function handleDropdownClick(e) {
    // if (e.key === 'account_center') {
    //   props.history.push('/account/center')
    // }
    if (e.key === 'setting') {
      props.history.push('/account/setting')
    }
    if (e.key === 'logout') {
      handleLogout();
    }
  }

  function onSearch(value) {
    // console.log('搜索词：' + value);
    const newRoute = '/search?q=' + value;
    props.history.push(newRoute);
    dispatch(updateCurrentRoute({currentRoute: newRoute}))
  }




  const menuHeaderDropdown = (
    <Menu onClick={(e) => handleDropdownClick(e)}>
      {/*<Menu.Item key="account_center">*/}
      {/*  <UserOutlined/>&nbsp;个人中心*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="setting">
        <SettingOutlined/>&nbsp;个人设置
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key="logout">
        <LogoutOutlined/>&nbsp;退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: '60px',
        width: '100%',
        background: '#FFFFFF',
        display: headerDisplay,
      }}
    >
      <div
        style={{
          float: 'left',
          width: '100px',
          height: '60px',
          margin: '5px 0px 10px 50px',
          background: 'rgba(255, 255, 255, 0.2)',
          // border: 'solid red'
        }}
      >
        <a href="/">
          <img style={{height: '50px', width: '90px'}}
               src='https://mytoolbox-1253569772.cos.ap-shanghai.myqcloud.com/avatar/logo.png'
          />
        </a>
      </div>

      <div style={{
        float: 'left',
        width: 'calc(40%)',
        // border: 'solid green',
        height: '60px',

      }}>
        <Menu
          theme="light"
          mode="horizontal"
          style={{height: '61px', paddingTop: '11px'}}
          selectedKeys={[menuKey]}
          items={items}
          onClick={handleMenuClick}
        />
      </div>


      <div style={{
        float: 'left',
        // border: 'solid blue',
        textAlign: 'right',
        verticalAlign: 'center',
        width: 'calc(60% - 200px)',
        marginRight: '50px',
      }}>



      {
        loginStatus === 'loggedIn' ?
          (
            <Space size='middle'>
              <div style={{verticalAlign: 'center'}}>
                <Search style={{height: '60px', paddingTop: '15px'}} onSearch={onSearch} size='middle' placeholder="快捷键D+F唤醒搜索" enterButton />
              </div>
              <Dropdown overlay={menuHeaderDropdown}>
                <div style={{height: '60px'}}>
                  <div style={{paddingTop: '20px'}}>
                    <Avatar size="small" src={currentUser.avatar} alt="avatar"/>
                    <span>&nbsp;{currentUser.username}</span>
                  </div>
                </div>
              </Dropdown>
            </Space>
          ) :
          (
            <Space size='middle'>
              <div style={{verticalAlign: 'center'}}>
                <Search style={{height: '60px', paddingTop: '15px'}} onSearch={onSearch} size='middle' placeholder="快捷键D+F唤醒搜索" enterButton />
              </div>
              <a href="/login">登录</a>
              <a href="/signup">注册</a>
            </Space>
          )
      }



      </div>
    </div>
  );

}


export default withRouter(Header);

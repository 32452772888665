import { createSlice } from '@reduxjs/toolkit'

export const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState: {
    currentUser: {},
    accessToken: '',
    loginStatus: "loggedOut",
  },
  reducers: {
    updateUserStatus: (state, action) => {
      console.log('update userStatus');
      console.log(action);
      state.currentUser = action.payload.currentUser;
      state.accessToken = action.payload.accessToken;
      state.loginStatus = action.payload.loginStatus;
    },

    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
  },
});

export const { updateUserStatus, updateAccessToken } = userStatusSlice.actions;

export default userStatusSlice.reducer
